<template>
  <v-container class="py-16">
    <hos-progress-circular
      overlay
      :loading="initLoading"
    ></hos-progress-circular>

    <!--Passengers info-->
    <v-row id="passengers-info" v-if="!initLoading && variant" justify="center">
      <v-col cols="12" md="3" order="1" class="pa-10 pa-md-3">
        <v-row class="text-h5 text-sm-h4 mt-10">Товары</v-row>
        <v-row>
          <v-divider></v-divider>
        </v-row>
        <v-row class="text-body-1 text-sm-h6 mt-5">
          <v-col> Билеты x {{ ticketsCount }}</v-col>
          <v-col align="end">{{
            variant.VariantTotal.Text + " " + variant.VariantTotal.Currency
          }}</v-col>
        </v-row>
        <v-row>
          <v-divider></v-divider>
        </v-row>
        <v-row class="text-body-1 text-sm-h6 mt-5">
          <v-col>Итог</v-col>
          <v-col align="end">{{
            variant.VariantTotal.Text + " " + variant.VariantTotal.Currency
          }}</v-col>
        </v-row>
        <v-row class="text-h6 mt-5">
          <v-col>
            <v-btn
              block
              elevation="5"
              color="orange"
              :loading="bookLoading"
              large
              :disabled="!comparer"
              @click="proceedToBooking"
            >
              Бронировать
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" md="9" order="0">
        <v-card color="" class="px-5 py-16">
          <!--Header-->
          <v-row justify="center">
            <h1>
              <span class="orange--text">Данные</span> пассажир<span
                v-if="passengers.length > 1"
                >ов</span
              ><span v-else>а</span>
            </h1>
          </v-row>
          <v-row justify="center" class="mb-1 mt-10">
            <v-tabs center-active grow color="orange">
              <v-tab v-for="(passenger, n) in passengers" :key="n"
                ><span v-if="passenger.firstname">{{
                  passenger.firstname
                }}</span
                ><span v-else>Пассажир {{ n + 1 }}</span></v-tab
              >
              <v-tab-item v-for="(passenger, n) in passengers" :key="n">
                <passenger-info
                  :countriesList="countries"
                  :docTypesList="docTypes"
                  :departureDate="'gowno'"
                  v-model="passengers[n]"
                  @updateValid="onValidUpdate($event, n)"
                >
                </passenger-info>
              </v-tab-item>
            </v-tabs>
          </v-row>
          <v-row>
            <v-col>
              <span class="text-h6">Note:</span>
              We will send the ticket info to your email, please enter a valid
              contact information!
            </v-col>
          </v-row>
          <v-form ref="contactInfo" v-model="contactInfoValid">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  color="orange"
                  min="2"
                  label="Телефон"
                  type="phone"
                  v-model="phone"
                  required
                  :rules="phoneRule"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  color="orange"
                  min="2"
                  label="Email"
                  type="email"
                  v-model="email"
                  required
                  :rules="emailRule"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <span class="text-h6">Note:</span>
                You are solely responsible for the data provided by you.
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>

    <!--The flight is unavailable-->
    <v-row
      v-if="!variant && !initLoading"
      class="fill-height py-16 my-16"
      justify="center"
    >
      <v-col class="text-center">
        <v-btn icon @click="$router.go()">
          <v-icon x-large color="primary">la-refresh</v-icon>
        </v-btn>
        <div class="text--disabled">
          Что-то пошло не так! Попробуйте ещё раз
        </div>
      </v-col>
    </v-row>

    <services
      :servicesProp="services"
      :segments="order !== null ? order.Pnr.Segments.Segment : null"
      :passengers="passengers"
      :countPassengers="passengers.length"
      :showDialog="ASDialog"
      :cancelLoading="cancelLoading"
      :payLoading="payLoading"
      :currency="order !== null ? order.Pnr.Prices.VariantTotal.Currency : null"
      :totalSum="
        order !== null ? parseFloat(this.order.Pnr.Prices.VariantTotal.Text) : 0
      "
      @cancel="cancelBooking()"
      @payment="onPayment($event[0], $event[1])"
    >
    </services>

    <!--show cart-->
    <div v-if="payDialog" class="text-center">
      <v-dialog
        scrollable
        persistent
        :width="$vuetify.breakpoint.mdAndUp ? '50%' : '95%'"
        v-model="payDialog"
      >
        <v-card>
          <v-card-title class="headline orange white--text">
            Товары
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <v-row class="text-body-1 text-md-h6">
                    <v-col cols="5" class="d-none d-md-block">
                      Билеты x {{ ticketsCount }}</v-col
                    >
                    <v-col
                      class="text-right font-weight-bold d-none d-md-block"
                    >
                      {{
                        book.Pnr.Prices.VariantTotal.Text +
                          " " +
                          book.Pnr.Prices.VariantTotal.Currency
                      }}
                    </v-col>

                    <v-col cols="12" class="d-md-none price-total mt-3">
                      <v-row>
                        <v-col cols="6" class="text-left pb-1"> Билеты </v-col>
                        <v-col cols="6" class="text-right pb-1">
                          Х {{ ticketsCount }}
                        </v-col>

                        <v-col class="text-right font-weight-bold pt-0">
                          {{
                            book.Pnr.Prices.VariantTotal.Text +
                              " " +
                              book.Pnr.Prices.VariantTotal.Currency
                          }}
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-row
                    v-if="selectedServicesCount"
                    class="text-body-1 text-md-h6"
                  >
                    <v-col cols="5" class="pt-0 d-none d-md-block">
                      Услуги x {{ servicesCount }}
                      <span v-if="selectedServicesCount > servicesCount">
                        <v-btn
                          icon
                          @click="
                            showUnavailableServices = !showUnavailableServices
                          "
                        >
                          <v-icon color="error">la-warning</v-icon>
                        </v-btn>
                      </span>
                    </v-col>
                    <v-col
                      class="text-right font-weight-bold pt-0 d-none d-md-block"
                    >
                      {{
                        servicesPaymentSum.toFixed(2) +
                          " " +
                          book.Pnr.Prices.VariantTotal.Currency
                      }}
                    </v-col>

                    <v-col cols="12" class="d-md-none price-total mt-3 mb-3">
                      <v-row>
                        <v-col cols="6" class="text-left pb-1"> Услуги </v-col>
                        <v-col cols="6" class="text-right pb-1">
                          Х {{ servicesCount }}
                        </v-col>

                        <v-col cols="3" class="text-left pt-0">
                          <span v-if="selectedServicesCount > servicesCount">
                            <v-btn
                              icon
                              @click="
                                showUnavailableServices = !showUnavailableServices
                              "
                            >
                              <v-icon color="error">la-warning</v-icon>
                            </v-btn>
                          </span>
                        </v-col>

                        <v-col
                          cols="9"
                          class="text-right font-weight-bold pt-2"
                        >
                          {{
                            servicesPaymentSum +
                              " " +
                              book.Pnr.Prices.VariantTotal.Currency
                          }}
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <transition name="slide-fade">
                    <v-row v-if="showUnavailableServices">
                      <v-col
                        cols="12"
                        class="
                          error--text
                          text-left
                          d-block d-md-none
                          pt-1
                          pb-1
                        "
                        >Недоступно</v-col
                      >
                      <v-col
                        cols="12"
                        v-for="(unaSer, i) in unavailableServices"
                        :key="unaSer + i"
                      >
                        <v-row>
                          <v-col
                            cols="12"
                            md="9"
                            class="error--text py-2 py-md-3"
                            >{{ unaSer }}</v-col
                          >
                          <v-col
                            cols="3"
                            class="error--text text-right d-none d-md-block"
                            >Недоступно</v-col
                          >
                        </v-row>
                      </v-col>
                    </v-row>
                  </transition>

                  <v-row class="mt-5">
                    <v-divider></v-divider>
                  </v-row>
                  <v-row class="text-h6 text-md-h5 font-weight-bold mt-5">
                    <v-col class="d-block d-md-none text-center"
                      >Итог:
                      {{
                        totalPaymentAmount +
                          " " +
                          book.Pnr.Prices.VariantTotal.Currency
                      }}</v-col
                    >

                    <v-col cols="4" class="d-none d-md-block">Итог</v-col>
                    <v-col align="end" class="d-none d-md-block">{{
                      totalPaymentAmount +
                        " " +
                        book.Pnr.Prices.VariantTotal.Currency
                    }}</v-col>
                  </v-row>

                  <v-row class="mt-10">
                    <span v-if="paymentError" class="error--text">{{
                      paymentError
                    }}</span>
                    <v-spacer></v-spacer>
                    <v-col cols="12" class="text-center text-md-right">
                      <v-btn
                        dark
                        text
                        :loading="cancelLoading"
                        color="orange"
                        :disabled="confirmLoading"
                        @click="cancelBooking"
                      >
                        Отмена
                      </v-btn>
                      <v-btn
                        dark
                        elevation="5"
                        :loading="confirmLoading"
                        color="orange"
                        @click="payment"
                      >
                        Оплатить
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <hos-error-modal v-model="hasErrors"></hos-error-modal>
  </v-container>
</template>

<script>
import Services from "@/components/Book/Services";
import PassengerInfo from "@/components/Book/PassengerInfo";
import HosErrorModal from "@/components/UI/HosErrorModal";
import ApiCalls from "@/mixins/booking/ApiCalls";
import Validators from "@/mixins/Validators";
import DateFormat from "@/mixins/DateFormat";
import PassengerHelper from "@/mixins/booking/PassengerHelper";
import HosProgressCircular from "@/components/UI/HosProgressCircular";

export default {
  name: "BookTkt",
  components: { HosProgressCircular, HosErrorModal, PassengerInfo, Services },
  mixins: [DateFormat, PassengerHelper, Validators, ApiCalls],
  props: ["segments", "adults", "kids", "bbs", "pult"],
  data() {
    return {
      contactInfoValid: false,
      validForms: [],
      hasErrors: false,
      readyToProceed: false,
      initLoading: true,
      bookLoading: false,
      payLoading: false,
      confirmLoading: false,
      cancelLoading: false,
      ASDialog: false,
      payDialog: false,
      showUnavailableServices: false,
      currentPass: 0,
      regnum: null,
      surname: null,
      menus: Array(this.passCount).fill(false),
      maxDate: new Date().toISOString().substr(0, 10),
      genders: [
        { text: "Мужской", value: "male" },
        { text: "Женский", value: "female" },
      ],
      categories: [
        { text: "Взрослый", value: "ААА" },
        { text: "Ребенок", value: "РБГ" },
        { text: "Младенец", value: "РМГ" },
        {
          text: "Военный",
          value: "MIL",
        },
      ],
      docTypes: [{ text: "Паспорт", value: "ПС" }],
      passengers: [],
      countries: [],
      variant: null,
      order: null,
      book: null,
      services: [],
      selectedServicesCount: 0,
      email: null,
      phone: null,
      paymentError: null,
      payIdVersion: 0,
      dialogServices: false,
    };
  },
  async mounted() {
    document.title = "Информация о пассажирах";

    this.passengers = this.generateModels();

    this.initLoading = true;
    await this.getCountries();
    await this.checkOption();
    await this.getDocTypes();
    this.initLoading = false;
  },
  watch: {
    currentPass() {
      this.$vuetify.goTo("#passengers-info", {
        offset: 100,
      });
    },
  },
  computed: {
    comparer() {
      let tmp = this.valid && this.contactInfoValid;
      this.setReady(tmp);
      return tmp;
    },
    valid() {
      let condition =
        this.validForms.every((e) => e) &&
        this.validForms.length === this.passengers.length;
      return condition;
    },
    passCount() {
      return parseInt(this.adults) + parseInt(this.kids) + parseInt(this.bbs);
    },
    ticketsCount() {
      return this.passCount * (this.cmp2 ? 2 : 1);
    },
    servicesBookingSum() {
      let sum = 0;
      this.services.forEach((s) => {
        s.Prices.forEach((p) => {
          if (p.Selected) sum += parseFloat(p.Total);
        });
      });

      return sum;
    },
    servicesCount() {
      let count = 0;
      this.book.Pnr.Prices.Price.forEach((p) => {
        if (p.DocType === "emd") {
          let ser = this.book.Pnr.Svcs.Svc.find(
            (s) => s.Id === p.SvcId && s.Status !== "HN" && s.Status !== "UN"
          );
          if (ser) count++;
        }
      });
      return count;
    },
    unavailableServices() {
      var check = this.book?.Pnr?.Svcs?.Svc?.filter(
        (s) => s.Status === "UN" || s.Status === "HN"
      ).map((s) => s.Name);
      return check;
    },
    servicesPaymentSum() {
      let sum = 0;
      this.book.Pnr.Prices.Price.forEach((p) => {
        if (p.DocType === "emd") {
          let ser = this.book.Pnr.Svcs.Svc.find(
            (s) => s.Id === p.SvcId && s.Status !== "HN" && s.Status !== "UN"
          );
          if (ser) sum += parseFloat(p.Total);
        }
      });
      return sum;
    },
    totalBookingAmount() {
      return (
        this.servicesBookingSum +
        parseFloat(this.order.Pnr.Prices.VariantTotal.Text)
      ).toFixed(2);
    },
    totalPaymentAmount() {
      return (
        this.servicesPaymentSum +
        parseFloat(this.book.Pnr.Prices.VariantTotal.Text)
      ).toFixed(2);
    },
  },
  methods: {
    changeService(price) {
      price.Selected = !price.Selected;
    },
    setReady(e) {
      this.readyToProceed = e;
    },
    nextBtnClickHandler() {
      if (this.$refs.form[this.currentPass].validate()) {
        this.passengers[this.currentPass].birthdate = this.formatDate(
          this.passengers[this.currentPass].dob
        );
        if (this.currentPass === this.passCount - 1) this.readyToProceed = true;
        else this.currentPass++;
      }
    },
    addDeleteService(price) {
      price.Selected = !price.Selected;
      this.selectedServicesCount += price.Selected ? 1 : -1;
    },
    anyPriceIsSelected(ser) {
      return ser.Prices.some((e) => e.Selected);
    },
    setServices() {
      this.services = this.order.Svcs.Svc.filter(
        (s) =>
          s.Booking !== "N/A" &&
          (s.Ssr || "").toLowerCase() !== "seat" &&
          s.ServiceType.toLowerCase() !== "p" &&
          s.Emd.toLowerCase() !== "emd-st" &&
          s.Name.toLowerCase() !== "выбор места"
      ).map((s) => {
        let prices = this.order.SvcPrices.Price.filter((p) => p.SvcId === s.Id); //здесь отрубались бесплатные услуги!!!

        return {
          Info: {
            Name: s.Name,
            Emd: s.Emd,
            Rfic: s.Rfic,
            Rfisc: s.Rfisc,
            ServiceType: s.ServiceType,
            Ssr: s.Ssr,
            Group: s.Group,
          },
          Prices: prices.map((p) => ({
            Total: p.Total,
            PassId: p.PassengerId,
            SegId: p.SegmentId,
            Currency: p.Currency,
            Selected: false,
          })),
        };
      });
    },

    onValidUpdate(data, n) {
      this.validForms.splice(n, 1, data);
    },

    onPayment(count, servs) {
      this.selectedServicesCount = count;
      this.services = servs;
      this.proceedToPayment();
    },
  },
  filters: {
    minPrice(v) {
      return v.Prices[0].Total + " " + v.Prices[0].Currency;
    },
  },
};
</script>

<style scoped>
.fields {
  border-radius: 8px;
  background-color: white;
}

@media only screen and (max-width: 600px) {
  .fields {
    background-color: #eceff1;
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}

.service {
  border-radius: 8px;
}
.service:hover {
  cursor: pointer;
}

.v-dialog-pass {
  margin: 10px 0 50px 0 !important;
}

.price-total {
  border-radius: 8px;
  background-color: #eceff1;
}
</style>
