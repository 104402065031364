<template>
  <div class="text-center">
    <v-dialog
      v-model="value"
      :width="$vuetify.breakpoint.mdAndUp ? '50%' : '95%'"
      persistent
    >
      <v-card>
        <v-card-title class="text-h5 red lighten-2 white--text">
          Произошла ошибка!
        </v-card-title>

        <v-card-text class="mt-5 text-h6">
          Что-то пошло не так при обработке вашего запроса, позвоните по
          телефону:
          <span class="text-h5 orange--text">{{
            $store.state.company.Phone
          }}</span>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="$emit('input', false)">
            ОК
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DateFormat from "@/mixins/DateFormat";

export default {
  name: "HosErrorModal",
  props: ["value"],
  mixins: [DateFormat],
};
</script>

<style scoped></style>
