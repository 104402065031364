import { render, staticRenderFns } from "./HosErrorModal.vue?vue&type=template&id=44bf8457&scoped=true"
import script from "./HosErrorModal.vue?vue&type=script&lang=js"
export * from "./HosErrorModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44bf8457",
  null
  
)

export default component.exports