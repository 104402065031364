<template>
  <div v-if="showDialog" class="text-center">
    <v-dialog persistent fullscreen v-model="showDialog">
      <v-card>
        <v-card-title class="headline grey lighten-2 text-h6 text-md-h5">
          Дополнительные услуги
        </v-card-title>

        <v-container fluid class="mt-4 mb-16 pt-5 pb-16">
          <v-row>
            <template v-for="seg in segments">
              <v-col
                cols="12"
                v-for="(servs, j) in servicesOnCategory"
                :key="'services_' + j + '_' + seg.Flight"
              >
                <v-card class="fill-height" v-if="servs.length !== 0">
                  <v-card-title
                    class="headline white--text light-blue lighten-2 py-2 pl-5"
                  >
                    <v-img
                      v-if="j === 0"
                      :src="require('@/assets/booking/bagagge.png')"
                      max-width="40"
                      contain
                      class="mr-4"
                    >
                    </v-img>
                    <v-img
                      v-else-if="j === 1"
                      :src="require('@/assets/booking/pets.png')"
                      max-width="40"
                      contain
                      class="mr-4"
                    >
                    </v-img>
                    <v-img
                      v-else-if="j === 2"
                      :src="require('@/assets/booking/food.png')"
                      max-width="40"
                      contain
                      class="mr-4"
                    >
                    </v-img>
                    <v-img
                      v-else
                      :src="require('@/assets/booking/other.png')"
                      max-width="40"
                      contain
                      class="mr-4"
                    >
                    </v-img>
                    <span class="text-body-1 text-md-h6">
                      На рейс:
                      {{
                        `${seg.Departure.City}&nbsp;&nbsp;-&nbsp;&nbsp;${seg.Arrival.City}`
                      }}
                    </span>
                  </v-card-title>

                  <v-card-text class="pt-1 px-0 px-md-5">
                    <v-slide-group show-arrows>
                      <v-slide-item
                        v-for="(ser, k) in getSegmentServices(seg.Id, j)"
                        :key="'ser_' + k + '_' + ser.Info.Name"
                        class="my-2 pa-2 ma-2"
                      >
                        <v-card
                          :color="
                            anyPriceIsSelected(ser)
                              ? '#CBE9E7'
                              : 'blue-grey lighten-5'
                          "
                          :width="$vuetify.breakpoint.smAndDown ? '150' : '210'"
                          class="my-4 mx-2 px-1 service"
                          elevation="3"
                        >
                          <v-card-title class="py-0 px-1 text-center">
                            <v-menu offset-y>
                              <template v-slot:activator="{ on, attrs }">
                                <v-chip
                                  color="blue-grey"
                                  label
                                  outlined
                                  v-bind="attrs"
                                  v-on="on"
                                  class="
                                      text-no-wrap text-body-2 text-md-body-1
                                      font-weight-bold
                                      text-left
                                    "
                                  style="width: 100%"
                                  ><div
                                    class="text-truncate text-center"
                                    style="width: 100%; display: inline-block"
                                  >
                                    {{ ser.Info.Name }}
                                  </div></v-chip
                                >
                              </template>
                              <div
                                class="
                                    text-body-2
                                    white--text
                                    blue-grey
                                    lighten-2
                                    py-1
                                    px-3
                                  "
                              >
                                {{ ser.Info.Name }}
                              </div>
                            </v-menu>
                          </v-card-title>

                          <v-card-actions
                            v-if="countPassengers == 1"
                            class="pb-0 pr-0 cursor-pointer"
                            @click="addDeleteService(ser.Prices[0])"
                          >
                            <v-row no-gutters>
                              <v-col class="text-left">
                                <span
                                  class="
                                      text-body-1 text-md-h6
                                      orange--text
                                    "
                                  >{{ ser | minPrice }}</span
                                >
                              </v-col>
                              <v-col cols="2" class="text-right mr-2">
                                <v-checkbox
                                  v-model="ser.Prices[0].Selected"
                                  @click="changeService(ser.Prices[0])"
                                  color="orange"
                                  class="mt-0"
                                  :hide-details="true"
                                >
                                </v-checkbox>
                              </v-col>
                            </v-row>
                          </v-card-actions>

                          <v-dialog
                            v-else
                            class="v-dialog-pass"
                            :width="
                              $vuetify.breakpoint.smAndDown ? '90%' : '50%'
                            "
                            :key="'pass_ser_' + j + '_' + ser.Info.Name"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-card-actions
                                class="pb-0 pr-0"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-row no-gutters>
                                  <v-col class="text-left">
                                    <span
                                      class="
                                          text-body-1 text-md-h6
                                          orange--text
                                        "
                                      >{{ ser | minPrice }}</span
                                    >
                                  </v-col>
                                  <v-col cols="2" class="text-right mr-2">
                                    <span>
                                      <v-icon
                                        v-if="$vuetify.breakpoint.smAndDown"
                                        color="blue"
                                        >mdi-credit-card</v-icon
                                      >
                                      <v-icon v-else large color="blue"
                                        >mdi-credit-card</v-icon
                                      >
                                    </span>
                                  </v-col>
                                </v-row>
                              </v-card-actions>
                            </template>
                            <v-card flat>
                              <v-card-title class="blue white--text py-2">
                                <v-row>
                                  <v-col
                                    class="
                                        text-body-1
                                        font-weight-bold
                                        text-sm-h6 text-left
                                        pt-4 pt-sm-3
                                      "
                                  >
                                    {{ ser.Info.Name }}:
                                    <span
                                      class="
                                          light-green--text
                                          text--accent-3 text-h6 text-sm-h5
                                        "
                                      >{{ ser | minPrice }}</span
                                    >
                                  </v-col>
                                  <v-col cols="2" class="text-h6 text-right">
                                    <v-icon class="pl-2" color="white"
                                      >mdi-cart-outline</v-icon
                                    >
                                  </v-col>
                                </v-row>
                              </v-card-title>

                              <v-card-text class="pa-0">
                                <v-simple-table>
                                  <template v-slot:default>
                                    <tbody>
                                      <tr
                                        v-for="(pass, k) in passengers"
                                        :key="pass.firstname + k"
                                        @click="addDeleteService(ser.Prices[k])"
                                      >
                                        <td class="text-left pr-0">
                                          <div
                                            class="text-body-1 text-md-h6"
                                            style="width: 90%"
                                          >
                                            {{
                                              pass.lastname +
                                                " " +
                                                pass.firstname
                                            }}
                                          </div>
                                        </td>
                                        <td class="text-right pl-0">
                                          <v-checkbox
                                            v-model="ser.Prices[k].Selected"
                                            @click="
                                              changeService(ser.Prices[k])
                                            "
                                            color="orange"
                                          >
                                          </v-checkbox>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </template>
                                </v-simple-table>
                              </v-card-text>
                            </v-card>
                          </v-dialog>
                        </v-card>
                      </v-slide-item>
                    </v-slide-group>
                  </v-card-text>
                </v-card>
              </v-col>
            </template>
          </v-row>
        </v-container>

        <v-app-bar bottom fixed :height="'75%'">
          <v-row>
            <v-col
              cols="12"
              md="6"
              class="text-center py-0 py-md-3 text-md-left"
            >
              <div
                class="
                    text-body-1 text-md-h6
                    font-weight-bold
                    py-md-2
                    text-center text-md-left
                  "
              >
                Итог:
                {{ totalBookingAmount + " " + currency }}
              </div>
            </v-col>

            <v-col
              cols="12"
              md="6"
              class="
                  text-center text-md-right
                  py-0 py-md-3
                  pt-md-4
                  mt-1 mt-md-0
                "
            >
              <v-btn
                v-if="$vuetify.breakpoint.mdAndUp"
                dark
                outlined
                :loading="cancelLoading"
                color="orange"
                :disabled="payLoading"
                class="mr-2"
                @click="$emit('cancel')"
              >
                Назад
              </v-btn>
              <v-btn
                v-else
                small
                dark
                outlined
                :loading="cancelLoading"
                color="orange"
                :disabled="payLoading"
                class="mr-2"
                @click="$emit('cancel')"
              >
                Назад
              </v-btn>

              <v-btn
                v-if="$vuetify.breakpoint.mdAndUp"
                dark
                :loading="payLoading"
                color="orange"
                @click="$emit('payment', [selectedServicesCount, services])"
              >
                Перейти к оплате
              </v-btn>
              <v-btn
                v-else
                small
                dark
                :loading="payLoading"
                color="orange"
                @click="$emit('payment', [selectedServicesCount, services])"
              >
                Перейти к оплате
              </v-btn>
            </v-col>
          </v-row>
        </v-app-bar>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ApiCalls from "@/mixins/booking/ApiCalls";

export default {
  name: "Services",
  mixins: [ApiCalls],
  props: {
    servicesProp: {
      type: Array,
      require: true,
    },
    segments: {
      type: Array,
      require: true,
    },
    passengers: {
      type: Array,
      require: true,
    },
    countPassengers: {
      type: Number,
      require: true,
    },
    showDialog: {
      type: Boolean,
      require: true,
    },
    cancelLoading: {
      type: Boolean,
      require: true,
    },
    payLoading: {
      type: Boolean,
      require: true,
    },
    currency: {
      type: String,
      require: true,
    },
    totalSum: {
      type: Number,
      require: true,
    },
  },
  data() {
    return {
      services: [],
      servicesOnCategory: [],
      selectedServicesCount: 0,
    };
  },
  watch: {
    servicesProp: function(newVal) {
      console.log(newVal);
      console.log("gggggggg");
      this.services = newVal;
      this.servicesOnCategory[0] = this.services.filter(
        (s) => s.Info.Group === "BG"
      );
      this.servicesOnCategory[1] = this.services.filter(
        (s) => s.Info.Group === "PT"
      );
      this.servicesOnCategory[2] = this.services.filter(
        (s) => s.Info.Group === "ML"
      );
      this.servicesOnCategory[3] = this.services.filter(
        (s) =>
          s.Info.Group !== "BG" &&
          s.Info.Group !== "PT" &&
          s.Info.Group !== "ML"
      );
    },
  },
  computed: {
    servicesBookingSum() {
      let sum = 0;
      this.services.forEach((s) => {
        s.Prices.forEach((p) => {
          if (p.Selected) sum += parseFloat(p.Total);
        });
      });
      return sum;
    },
    totalBookingAmount() {
      return (this.servicesBookingSum + parseFloat(this.totalSum)).toFixed(2);
    },
  },
  methods: {
    getSegmentServices(segId, group) {
      let ser = this.servicesOnCategory[group]
        .map((s) => {
          let prices = s.Prices.filter((p) => p.SegId === segId);
          if (prices.length)
            return {
              Info: s.Info,
              Prices: prices,
            };
        })
        .filter((s) => s !== undefined);
      return ser;
    },
    anyPriceIsSelected(ser) {
      return ser.Prices.some((e) => e.Selected);
    },
    addDeleteService(price) {
      price.Selected = !price.Selected;
      this.selectedServicesCount += price.Selected ? 1 : -1;
    },
    changeService(price) {
      price.Selected = !price.Selected;
    },
  },
  filters: {
    minPrice(v) {
      return v.Prices[0].Total + " " + v.Prices[0].Currency;
    },
  },
};
</script>

<style>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}

.service {
  border-radius: 8px;
}
.service:hover {
  cursor: pointer !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.v-dialog-pass {
  margin: 10px 0 50px 0 !important;
}
</style>
